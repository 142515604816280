import React, { useCallback } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  ConstructorFormSection,
  ConstructorStepSection,
  TextEditor,
} from 'features/project/Constructor/subfeatures';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';
import { ProjectWriteContext } from '../../ProjectWriteContext';
import { Step } from '../../types';
import './style.scss';
import {
  errorsUnit,
  hasSomeInputUnit,
  hypothesisState,
  problemState,
  progressInvariantUnitsUnit,
  purposeState,
  rationaleState,
  visitedUnit,
} from './units';

const b = block('concept-step');

type Props = {};

function Concept({}: Props) {
  const { mode } = useRequiredContext(ConstructorConfigContext);
  const fields = I18n.useText(i18nData).steps.concept.fields;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <div className={b()}>
      <ConstructorFormSection.Component>
        <ConstructorStepSection.Component
          title={fields.purpose.label}
          tooltip={fields.purpose.tooltip}
        >
          <TextEditor.Component
            formElementState={purposeState}
            placeholder={fields.purpose.placeholder}
            errorRows={1}
            imageContainer="projects"
            onBlur={handleBlur}
          />
        </ConstructorStepSection.Component>
        {mode === 'full' && (
          <ConstructorStepSection.Component
            title={fields.problem.label}
            tooltip={fields.problem.tooltip}
          >
            <TextEditor.Component
              formElementState={problemState}
              placeholder={fields.problem.placeholder}
              errorRows={1}
              imageContainer="projects"
              onBlur={handleBlur}
            />
          </ConstructorStepSection.Component>
        )}
        <ConstructorStepSection.Component
          title={fields.hypothesis.label}
          tooltip={fields.hypothesis.tooltip}
        >
          <TextEditor.Component
            formElementState={hypothesisState}
            placeholder={fields.hypothesis.placeholder}
            errorRows={1}
            imageContainer="projects"
            onBlur={handleBlur}
          />
        </ConstructorStepSection.Component>
        {mode === 'full' && (
          <ConstructorStepSection.Component
            title={fields.rationale.label}
            tooltip={fields.rationale.tooltip}
          >
            <TextEditor.Component
              className={b('text-editor')}
              formElementState={rationaleState}
              placeholder={fields.rationale.placeholder}
              errorRows={1}
              imageContainer="projects"
              onBlur={handleBlur}
            />
          </ConstructorStepSection.Component>
        )}
      </ConstructorFormSection.Component>
    </div>
  );
}

const Component = React.memo(Concept);

export const step: Step = {
  key: 'concept',
  Form: Component,
  errorsUnit,
  visitedUnit,
  progressInvariantUnitsUnit,
  hasSomeInputUnit,
  getProjectData: ({ serverProject }) => {
    const lang = getProjectLanguage();

    return {
      research_aim: I18n.makeMultilangFormState(
        purposeState,
        serverProject?.research_aim,
      ).getMergedMultilingString(lang),

      hypothesis: I18n.makeMultilangFormState(
        hypothesisState,
        serverProject?.hypothesis,
      ).getMergedMultilingString(lang),

      crowdsourcing_justification: I18n.makeMultilangFormState(
        rationaleState,
        serverProject?.crowdsourcing_justification,
      ).getMergedMultilingString(lang),

      problem: I18n.makeMultilangFormState(
        problemState,
        serverProject?.problem,
      ).getMergedMultilingString(lang),
    };
  },
  fillFromExistingProject: ({ project, language }) => {
    const t = (str: M.MultilingString) =>
      I18n.getMultilingTranslation(language, str);

    if (project.research_aim) {
      purposeState.units.value.setState(t(project.research_aim));
    }

    if (project.hypothesis) {
      hypothesisState.units.value.setState(t(project.hypothesis));
    }

    if (project.problem) {
      problemState.units.value.setState(t(project.problem));
    }

    if (project.crowdsourcing_justification) {
      rationaleState.units.value.setState(
        t(project.crowdsourcing_justification),
      );
    }
  },
  resetState: () => {
    visitedUnit.resetState();
  },
  resetStateAfterDelay: () => {
    problemState.formNode.reset();
    rationaleState.formNode.reset();
    hypothesisState.formNode.reset();
    purposeState.formNode.reset();
  },
};
