import React, { useCallback } from 'react';

import { TextInput, Button } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { TextEditor } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import {
  AbstractMaterialStateInstance,
  MaterialKey,
  MaterialConstructorWidget,
} from '../../../types';
import './style.scss';

const b = block('constructor-widget-edit-content-for-material');

type Props<
  StateInstance extends AbstractMaterialStateInstance<MaterialKey, any>,
> = {
  index: number;
  stateInstance: StateInstance;
  constructorWidget: MaterialConstructorWidget<any, any, any>;
};

function EditContent<
  StateInstance extends AbstractMaterialStateInstance<MaterialKey, any>,
>({ index, constructorWidget, stateInstance }: Props<StateInstance>) {
  const text = I18n.useText(i18nData);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleSaveButtonClick = useCallback(() => {
    const isValid = stateInstance.validate();

    if (isValid) {
      stateInstance.mode.setState('preview');
      saveProject();
    }
  }, [saveProject, stateInstance]);

  if (stateInstance.kind === 'test') {
    return constructorWidget.FormExtension ? (
      <constructorWidget.FormExtension index={index} instance={stateInstance} />
    ) : null;
  }

  return (
    <section className={b()}>
      <TextInput.Component
        formElementState={stateInstance.materialTitle}
        placeholder={text.steps.shared.materials.titleInputPlaceholder}
      />
      <TextEditor.Component
        key={`material-explanation-editor-${index}`}
        className={b('material-explanation-editor')}
        formElementState={stateInstance.materialExplanation}
        placeholder={text.steps.shared.materials.descriptionInputPlaceholder}
        errorRows={0}
        imageContainer="projects"
      />
      {constructorWidget.FormExtension && (
        <constructorWidget.FormExtension
          index={index}
          instance={stateInstance}
        />
      )}
      <footer className={b('footer')}>
        <Button.Component
          type="button"
          onClick={handleSaveButtonClick}
          color="accent-2"
          variant="contained"
          disabled={stateInstance.kind === 'notSelected'}
        >
          {text.saveButtonLabel}
        </Button.Component>
      </footer>
    </section>
  );
}

export const Component = React.memo(EditContent);
