import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  file: File;
  container: 'projects' | 'project_books' | 'courses' | 'quiz';
};

export const fileUpload = makeService<InputData, M.URL>(
  'file_upload',
  'post-file',
);
