import React, { useCallback } from 'react';

import { TextInput, Button } from 'components';
import { TextEditor } from 'features/abstract';
import { QuizWriteContext } from 'features/quiz/Constructor/QuizWriteContext';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../../../i18n.json';
import {
  QuestionConstructor,
  CachedQuestionInstance,
  QuestionPath,
} from '../../../../types';
import './style.scss';

const b = block('quiz-constructor-edit-content-for-question');

type Props = {
  path: QuestionPath;
  cachedInstance: CachedQuestionInstance;
  constructorQuestion: QuestionConstructor<any, any, any>;
};

function EditContent({ path, constructorQuestion, cachedInstance }: Props) {
  const text = I18n.useText(i18nData);

  const stateInstance = cachedInstance.useStateInstance();

  const { saveQuiz } = useRequiredContext(QuizWriteContext);

  const handleSaveButtonClick = useCallback(() => {
    const isValid = cachedInstance.getStateInstance().validate();

    if (isValid) {
      cachedInstance.mode.setState('preview');
      saveQuiz();
    }
  }, [cachedInstance, saveQuiz]);

  return (
    <div className={b()}>
      <TextInput.Component
        formElementState={stateInstance.questionText.formElementState}
        placeholder={text.questions.questionTextLabel}
      />
      <TextEditor.Component
        key={`question-explanation-textarea-${path.index}`}
        className={b('question-explanation-textarea')}
        formElementState={stateInstance.questionExplanation.formElementState}
        placeholder={text.questions.questionExplanationLabel}
        errorRows={0}
        imageContainer="quiz"
      />
      {constructorQuestion.FormExtension && (
        <constructorQuestion.FormExtension instance={stateInstance} />
      )}
      <TextEditor.Component
        key={`answer-explanation-textarea-${path.index}`}
        className={b('answer-explanation-textarea')}
        formElementState={stateInstance.answerExplanation.formElementState}
        placeholder={text.explanationLabel}
        errorRows={0}
        imageContainer="quiz"
      />
      <div className={b('footer')}>
        <Button.Component
          type="button"
          onClick={handleSaveButtonClick}
          color="accent-2"
          variant="contained"
        >
          {text.buttons.save}
        </Button.Component>
      </div>
    </div>
  );
}

export const Component = React.memo(EditContent);
