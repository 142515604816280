import React, { useCallback } from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import {
  ConstructorFormSection,
  ConstructorStepSection,
  TextEditor,
} from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ProjectWriteContext } from '../../../ProjectWriteContext';
import { descriptionState } from '../units/description';

type Props = {};

function DescriptionEditor({}: Props) {
  const text = I18n.useText(i18nData);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <ConstructorFormSection.Component>
      <ConstructorStepSection.Component
        title={text.steps.conclusions.descriptionTextEditor.label}
      >
        <TextEditor.Component
          formElementState={descriptionState}
          placeholder={text.steps.conclusions.descriptionTextEditor.placeholder}
          imageContainer="projects"
          onBlur={handleBlur}
        />
      </ConstructorStepSection.Component>
    </ConstructorFormSection.Component>
  );
}

export const Component = React.memo(DescriptionEditor);
